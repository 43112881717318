<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';

import {required, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import appConfig from '@/app.config';
import Layout from '../../layouts/main';
import {customersData} from './data-customers';

/**
 * Customers component
 */
export default {
  page: {
    title: 'Customers',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      customersData,
      title: 'Customers',
      items: [
        {
          text: 'Ecommerce',
          href: '/',
        },
        {
          text: 'Customers',
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      customers: {
        username: '',
        phone: '',
        email: '',
        address: '',
        balance: '',
      },
    };
  },
  setup() {
    return {v$: useVuelidate()};
  },
  validations: {
    customers: {
      username: {
        required: helpers.withMessage('Username is required', required),
      },

      phone: {
        required: helpers.withMessage('Phone is required', required),
      },
      email: {required: helpers.withMessage('Email is required', required)},
      address: {
        required: helpers.withMessage('Address is required', required),
      },
      balance: {
        required: helpers.withMessage('Balance is required', required),
      },
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      const currentDate = new Date();
      this.customersData.push({
        username: this.customers.username,
        phone: this.customers.phone,
        email: this.customers.email,
        address: this.customers.address,
        balance: this.customers.balance,
        rating: '4.3',
        date: `${currentDate.getHours()}:${currentDate.getMinutes()}`,
      });
      this.showModal = false;
      this.customers = {};

      this.submitted = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-4">
                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input
                      class="form-control"
                      placeholder="Search..."
                      type="text"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="text-sm-end">
                  <button
                    class="btn btn-success btn-rounded mb-2 me-2"
                    type="button"
                    @click="showModal = true"
                  >
                    <i class="mdi mdi-plus me-1"></i> New Customers
                  </button>
                  <b-modal
                    v-model="showModal"
                    body-class="p-3"
                    hide-footer
                    title="Add New Customer"
                    title-class="text-black font-18"
                  >
                    <form @submit.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="name">Event Name</label>
                            <input
                              id="name"
                              v-model="customers.username"
                              :class="{
                                'is-invalid':
                                  submitted && v$.customers.username.$error,
                              }"
                              class="form-control"
                              placeholder="Insert username"
                              type="text"
                            />

                            <div
                              v-if="submitted && v$.customers.username.$error"
                              class="invalid-feedback"
                            >
                              <span
                                v-if="v$.customers.username.required.$message"
                              >{{
                                  v$.customers.username.required.$message
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="phone">Phone</label>
                            <input
                              id="phone"
                              v-model="customers.phone"
                              :class="{
                                'is-invalid':
                                  submitted && v$.customers.phone.$error,
                              }"
                              class="form-control"
                              placeholder="Insert phone"
                              type="text"
                            />
                            <div
                              v-if="submitted && v$.customers.phone.$error"
                              class="invalid-feedback"
                            >
                              <span
                                v-if="v$.customers.phone.required.$message"
                              >{{
                                  v$.customers.phone.required.$message
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="email">Email</label>
                            <input
                              id="email"
                              v-model="customers.email"
                              :class="{
                                'is-invalid':
                                  submitted && v$.customers.email.$error,
                              }"
                              class="form-control"
                              placeholder="Insert email"
                              type="email"
                            />
                            <div
                              v-if="submitted && v$.customers.email.$error"
                              class="invalid-feedback"
                            >
                              <span
                                v-if="v$.customers.email.required.$message"
                              >{{
                                  v$.customers.email.required.$message
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="address">Address</label>
                            <input
                              id="address"
                              v-model="customers.address"
                              :class="{
                                'is-invalid':
                                  submitted && v$.customers.address.$error,
                              }"
                              class="form-control"
                              placeholder="Insert address"
                              type="text"
                            />
                            <div
                              v-if="submitted && v$.customers.address.$error"
                              class="invalid-feedback"
                            >
                              <span
                                v-if="v$.customers.address.required.$message"
                              >{{
                                  v$.customers.address.required.$message
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="balance">Balance</label>
                            <input
                              id="balance"
                              v-model="customers.balance"
                              :class="{
                                'is-invalid':
                                  submitted && v$.customers.balance.$error,
                              }"
                              class="form-control"
                              placeholder="Insert balance"
                              type="text"
                            />
                            <div
                              v-if="submitted && v$.customers.balance.$error"
                              class="invalid-feedback"
                            >
                              <span
                                v-if="v$.customers.balance.required.$message"
                              >{{
                                  v$.customers.balance.required.$message
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="showModal = false"
                        >Close
                        </b-button
                        >
                        <b-button class="ms-1" type="submit" variant="success"
                        >Create event
                        </b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
              <!-- end col-->
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap align-middle">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>Phone / Email</th>
                  <th>Address</th>
                  <th>Rating</th>
                  <th>Wallet Balance</th>
                  <th>Joining Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="customers in customersData" :key="customers.id">
                  <td>
                    <div class="form-check font-size-16">
                      <input
                        :id="`customCheck${customers.id}`"
                        class="form-check-input"
                        type="checkbox"
                      />
                      <label
                        :for="`customCheck${customers.id}`"
                        class="form-check-label"
                      >&nbsp;</label
                      >
                    </div>
                  </td>
                  <td>{{ customers.username }}</td>
                  <td>
                    <p class="mb-1">{{ customers.phone }}</p>
                    <p class="mb-0">{{ customers.email }}</p>
                  </td>
                  <td>{{ customers.address }}</td>
                  <td>
                      <span class="badge bg-success font-size-12">
                        <i class="mdi mdi-star me-1"></i>
                        {{ customers.rating }}
                      </span>
                  </td>
                  <td>{{ customers.balance }}</td>
                  <td>{{ customers.date }}</td>
                  <td>
                    <b-dropdown
                      class="card-drop"
                      menu-class="dropdown-menu-end"
                      right
                      toggle-class="p-0"
                      variant="white"
                    >
                      <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                      </template>

                      <b-dropdown-item>
                        <i class="fas fa-pencil-alt text-success me-1"></i>
                        Edit
                      </b-dropdown-item>

                      <b-dropdown-item>
                        <i class="fas fa-trash-alt text-danger me-1"></i>
                        Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item disabled">
                <a
                  aria-label="Previous"
                  class="page-link"
                  href="javascript: void(0);"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript: void(0);">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">4</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">5</a>
              </li>
              <li class="page-item">
                <a
                  aria-label="Next"
                  class="page-link"
                  href="javascript: void(0);"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
