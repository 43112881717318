/* eslint-disable */
const customersData = [
  {
    id: 1,
    username: 'Stephen Rash',
    phone: '325-250-1106',
    email: 'StephenRash@teleworm.us',
    address: '2470 Grove Street Bethpage, NY 11714',
    rating: '4.2',
    balance: '$5,412',
    date: '07 Oct, 2019',
  },
  {
    id: 2,
    username: 'Juan Mays',
    phone: '443-523-4726',
    email: 'JuanMays@armyspy.com',
    address: '3755 Harron Drive Salisbury, MD 21875',
    rating: '4.0',
    balance: '$5,632',
    date: '06 Oct, 2019',
  },
  {
    id: 3,
    username: 'Scott Henry',
    phone: '704-629-9535',
    email: 'ScottHenry@jourrapide.com',
    address: '3632 Snyder Avenue Bessemer City, NC 28016',
    rating: '4.4',
    balance: '$7,523',
    date: '06 Oct, 2019',
  },
  {
    id: 4,
    username: 'Cody Menendez',
    phone: '701-832-5838',
    email: 'CodyMenendez@armyspy.com',
    address: '4401 Findley Avenue Minot, ND 58701',
    rating: '4.1',
    balance: '$6,325',
    date: '05 Oct, 2019',
  },
  {
    id: 5,
    username: 'Jason Merino',
    phone: '706-219-4095',
    email: 'JasonMerino@dayrep.com',
    address: '3159 Holly Street Cleveland, GA 30528',
    rating: '3.8',
    balance: '$4,523',
    date: '04 Oct, 2019',
  },
  {
    id: 6,
    username: 'Kyle Aquino',
    phone: '415-232-5443',
    email: 'KyleAquino@teleworm.us',
    address: '4861 Delaware Avenue San Francisco, CA 94143',
    rating: '4.0',
    balance: '$5,412',
    date: '03 Oct, 2019',
  },
  {
    id: 7,
    username: 'David Gaul',
    phone: '314-483-4679',
    email: 'DavidGaul@teleworm.us',
    address: '1207 Cottrill Lane Stlouis, MO 63101',
    rating: '4.2',
    balance: '$6,180',
    date: '02 Oct, 2019',
  },
  {
    id: 8,
    username: 'John McCray',
    phone: '253-661-7551',
    email: 'JohnMcCray@armyspy.com',
    address: '3309 Horizon Circle Tacoma, WA 98423',
    rating: '4.1',
    balance: '$5,2870',
    date: '02 Oct, 2019',
  },
];

export {customersData};
